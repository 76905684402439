<template>
  <v-container class="pa-md-5" fluid>
    <v-row>
      <v-col cols="12">
        <h2 class="secondary--text">Formato de impresión</h2>
        <v-divider class="mb-8 mt-2"></v-divider>
        <v-row>
          <v-col md="6">
            <input type="file" @change="handleFileUpload" />
            <button @click="generatePdf">Generate Invoice</button>
          </v-col>
          <v-col md="6">
            <iframe
              v-if="pdfUrl"
              :src="pdfUrl"
              style="width: 100%; height: 600px"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { PDFDocument, StandardFonts } from "pdf-lib";
import { formatCurrency } from "../helpers/formatters";

export default {
  data() {
    return {
      pdfTemplate: null, // To store the uploaded PDF file
      invoiceData: {}, // Store your fetched invoice data here
      pdfUrl: null,
    };
  },
  methods: {
    formatMoney(x) {
      return formatCurrency(x);
    },
    // Handle file upload
    handleFileUpload(event) {
      const file = event.target.files[0];
      const fileReader = new FileReader();

      fileReader.onload = () => {
        this.pdfTemplate = new Uint8Array(fileReader.result);
      };
      fileReader.readAsArrayBuffer(file);
    },

    // Generate the PDF with invoice data
    async generatePdf() {
      try {
        if (!this.pdfTemplate) {
          alert("Please upload a PDF template");
          return;
        }

        // Load the uploaded PDF
        const pdfDoc = await PDFDocument.load(this.pdfTemplate);
        const pages = pdfDoc.getPages();
        let firstPage = pages[0];

        if (!firstPage) {
          return null;
        }

        const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
        firstPage = this.drawPage({}, { page: firstPage, font });

        const pdfBytes = await pdfDoc.save();
        this.pdfUrl = URL.createObjectURL(
          new Blob([pdfBytes], { type: "application/pdf" })
        );
      } catch (error) {
        console.log("error", error);
      }
    },

    drawPage(invoiceData, { page, font }) {
      const { height } = page.getSize();
      const fontSize = 12;
      const margin = 50;
      const lineHeight = fontSize + 4;

      page.drawText(invoiceData?.name || "", {
        x: margin,
        y: height - margin,
        size: fontSize,
        font,
      });
      page.drawText(invoiceData?.specialties || "", {
        x: margin,
        y: height - margin - lineHeight,
        size: fontSize,
        font,
      });
      page.drawText(invoiceData?.phone || "", {
        x: margin,
        y: height - margin - 2 * lineHeight,
        size: fontSize,
        font,
      });

      page.drawText(`Fecha: ${invoiceData?.date}`, {
        x: margin,
        y: height - margin - 4 * lineHeight,
        size: fontSize,
        font,
      });
      page.drawText(`Código: ${invoiceData?.code}`, {
        x: margin,
        y: height - margin - 5 * lineHeight,
        size: fontSize,
        font,
      });
      page.drawText(`Sede: ${invoiceData?.address}`, {
        x: margin,
        y: height - margin - 6 * lineHeight,
        size: fontSize,
        font,
      });
      page.drawText(`Paciente: ${invoiceData?.patientName}`, {
        x: margin,
        y: height - margin - 7 * lineHeight,
        size: fontSize,
        font,
      });

      // Add table header
      const tableTop = height - margin - 10 * lineHeight;
      page.drawText("Descripción", {
        x: margin,
        y: tableTop,
        size: fontSize,
        font,
      });
      page.drawText("Cantidad", {
        x: margin + 200,
        y: tableTop,
        size: fontSize,
        font,
      });
      page.drawText("Precio Und.", {
        x: margin + 300,
        y: tableTop,
        size: fontSize,
        font,
      });
      page.drawText("Importe", {
        x: margin + 400,
        y: tableTop,
        size: fontSize,
        font,
      });

      // Add table items
      let itemY = tableTop - lineHeight;
      invoiceData?.items?.forEach((item) => {
        page.drawText(item?.description, {
          x: margin,
          y: itemY,
          size: fontSize,
          font,
        });
        page.drawText(String(item?.quantity), {
          x: margin + 200,
          y: itemY,
          size: fontSize,
          font,
        });
        page.drawText(`${this.formatMoney(invoiceData, item?.unitPrice)}`, {
          x: margin + 300,
          y: itemY,
          size: fontSize,
          font,
        });
        page.drawText(
          `${this.formatMoney(invoiceData, item?.quantity * item?.unitPrice)}`,
          {
            x: margin + 400,
            y: itemY,
            size: fontSize,
            font,
          }
        );
        itemY -= lineHeight;
      });

      page.drawText(
        `Total: ${this.formatMoney(invoiceData, invoiceData?.total)}`,
        {
          x: margin + 400,
          y: itemY - lineHeight,
          size: fontSize,
          font,
        }
      );
      page.drawText(`Moneda: ${invoiceData?.currency || "DOP"}`, {
        x: margin + 400,
        y: itemY - lineHeight - 18,
        size: fontSize,
        font,
      });
    },
  },
};
</script>
